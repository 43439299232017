import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Logo from 'assets/images/logo-dark.svg';
import {ISSUED, PARTIALLY_PAID, PAID, currencies} from 'components/constants';
import AppContext from 'components/App/AppContext';
import {initializeCheckout} from 'api/routes/wallet';
import {currenciesEnum, getError} from 'utils/helpers';
import Alert from 'utils/Alert/Alert';
import CustomSpinner from 'utils/Spinner/Spinner';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import {formValidator} from 'utils/FormValidator/FormValidator';
import {getInvoiceInfo} from 'api/routes/invoice';

const InvoiceHeader = ({invoice, refetch}) => {
    const {API, apiVersion, partial, status} = invoice;
    const {checkoutData} = invoice?.metadata || {};
    const initialAmount = invoice.amount || checkoutData?.metadata?.amount;
    const currency = checkoutData?.currency;

    const paid = status === PAID;

    const {reference} = useParams();
    const {setCheckoutData, metadata} = useContext(AppContext);

    const isPartial = partial?.is_partial;
    const totalPaid = partial?.installments?.reduce((acc, curr) => acc + curr, 0) || 0;

    const canPay = (initialAmount || 0) - totalPaid;

    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({amount: 0});
    const [formErrors, setFormErrors] = useState({});

    const isPartialClss = isPartial
        ? 'flex-column flex-md-row align-items-start align-md-items-center '
        : 'align-items-center';

    const showBtn = status === ISSUED || status === PARTIALLY_PAID || paid;

    useEffect(() => {
        setFormValues({amount: canPay});
    }, [canPay]);

    const handleNumber = ({target: {name, value}}) => {
        handleInput(name, value);
    };

    const handleInput = (name, value) => {
        setFormValues(prev => ({
            ...prev,
            [name]: value,
        }));

        setFormErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const initiateCharge = async payload => {
        try {
            setLoading(true);
            setErrorMsg();

            const invoiceRes = await API.get(getInvoiceInfo(reference));
            const invoice = invoiceRes?.data?.data;

            if (invoice?.status !== 'issued') {
                setErrorMsg('Invalid Invoice');
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
                return;
            }

            const response = await API.post(
                `${apiVersion}${initializeCheckout}`,
                {
                    ...payload,
                    currency,
                    walletId: checkoutData?.walletId,
                    metadata: {type: 'invoices', ...invoice},
                },
                {headers: {Authorization: checkoutData?.publicKey}}
            );

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                setLoading(false);
                setCheckoutData({
                    url: data?.data?.authorization_url,
                    refetch,
                });
            } else setError(data?.message);
        } catch (error) {
            setError(getError(error?.response?.data || error?.response || error));
        }
    };

    const setError = message => {
        setLoading(false);
        setErrorMsg(message);
    };

    const lowBalance =
        canPay - formValues?.amount < 500 &&
        canPay !== formValues?.amount &&
        currency === currencies.NGN;

    const handlePayment = () => {
        if (isPartial) {
            if (formValues?.amount > canPay) {
                return setFormErrors({amount: `Amount can not be greater than ${canPay}`});
            }

            if (lowBalance) {
                return setFormErrors({
                    amount: `The minimum payable balance is ${currenciesEnum.NGN}500. Please update the amount`,
                });
            }

            const {formErrors, isValid} = formValidator(formValues);

            return isValid
                ? initiateCharge({amount: formValues?.amount})
                : setFormErrors(formErrors);
        }

        initiateCharge({amount: initialAmount});
    };

    return (
        <>
            {errorMsg && <Alert msg={errorMsg} cls="px-5" onClose={() => setErrorMsg()} />}
            <div className={`header flex__between ${isPartialClss}`}>
                <img
                    src={invoice?.logo || metadata?.company_logo || Logo}
                    alt=""
                    style={{height: '35px', maxWidth: '165px', objectFit: 'contain'}}
                />
                {showBtn && (
                    <div className="d-flex mt-2 mt-md-0">
                        {isPartial && !paid && (
                            <CustomInputBox
                                icon={currency}
                                cls="mb-0 me-3 text-dark mt-1"
                                required
                                name="amount"
                                error={formErrors?.amount}
                                value={formValues?.amount}
                                placeholder="Enter Amount"
                                onChange={handleNumber}
                                initialValue={canPay}
                            />
                        )}
                        <div>
                            <button
                                className={`btn btn--sm px-5 ${
                                    paid ? 'btn-success' : 'btn--primary '
                                }`}
                                onClick={paid ? () => {} : handlePayment}
                                disabled={loading}
                            >
                                {loading ? (
                                    <CustomSpinner />
                                ) : paid ? (
                                    <h4 className="m-0 fw-bold">Paid</h4>
                                ) : (
                                    'Make Payment'
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default InvoiceHeader;
